import React from 'react'

const ConfirmationPage = () => (
  <>
    <section className="mw8-ns center tc pa3 mt4">
      <h1 className="fw5">Seu tributo foi criado com sucesso.</h1>
      <p className="f4 mb4">
        Por favor, aguarde alguns minutos para que ele seja disponibilizado no site.
      </p>
      <p className="f5 gray lh-copy">
        Para encontrá-lo, basta buscar pelo nome da pessoa que faleceu no campo de busca na página
        inicial.
        <br />
        Caso precise realizar alguma alteração,
        <a href="/contact" className="blue dib mh1">
          clique aqui
        </a>
        para entrar em contato conosco.
      </p>
    </section>
  </>
)

export default ConfirmationPage
